import request from '@/auth/jwt/request'

/**
 * @description 配置分类 -- 配置分类列表
 * @param {Object} param params {Object} 传值参数
 */
export const configTabListApi = params => { return request('configTab/list', 'get', params) }

/**
 * @description 配置分类 -- 增加修改配置分类
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const configTabSaveApi = data => { return request('configTab/save', 'post', {}, data) }

/**
 * @description 配置分类 -- 配置分类状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const configTabStatusApi = data => { return request('configTab/status', 'get', data) }

/**
 * @description 配置分类 -- 下拉顶级配置分类
 * @param {Object} param params {Object} 传值参数
 */
export const configTabSelectApi = () => { return request('configTab/select', 'get') }

/**
 * @description 配置分类 -- 删除配置分类
 * { id = x }
 */
export const configTabDeleteApi = data => { return request('configTab/delete', 'delete', data) }