<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="4" lg="3">
          <b-form-group
            id="fieldset-horizontal"
            label-cols="4"
            label-cols-lg="auto"
            label-cols-md="auto"
            label="配置名称"
            label-for="input-horizontal"
          >
            <b-form-input id="input-horizontal"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="d-flex justify-content-end mb-1">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary" v-if="$can('update', 'department')" @click="shallShowModal = true"> 添加配置 </b-button>
        </b-col>
      </b-row>

      <!-- table -->
      <vxe-table ref="xTable" border :auto-resize="true" :data="tableData"
        highlight-current-row :loading="loading" header-row-class-name="false"
        :tree-config="{children: 'childs'}" :scroll-x="{enabled: true}" :scroll-y="{enabled: true}"
      >
        <vxe-table-column field="id" title="配置ID" width="80"></vxe-table-column>
        <vxe-table-column field="name" tree-node title="配置分类名称" min-width="150"></vxe-table-column>
        <vxe-table-column field="engName" title="配置关键字" min-width="120"></vxe-table-column>
        <vxe-table-column field="sort" title="排序" width="100" align="center"></vxe-table-column>
        <vxe-table-column field="icon" title="配置图标" min-width="180" align="center"></vxe-table-column>
        <vxe-table-column field="status" title="配置状态" width="100" align="center">
          <template v-slot="{ row }">
            <div v-if="$can('status', 'department')">
              <b-form-checkbox @change="statusEvent(row.id)" :checked=" row.status == 1 ? true:false" class="custom-control-primary" name="check-button" switch />
            </div>
            <div v-else>无权限</div>
          </template>
        </vxe-table-column>

        <vxe-table-column field="action" title="操作" align="center" width="100"  class-name="vxe-table-padding">
          <template v-slot="{ row }">
            <div class="text-nowrap">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25"/>
                </template>
                <b-dropdown-item @click="editEvent(row)" v-if="$can('update', 'department')">
                <feather-icon icon="InfoIcon" class="mr-50"/>
                <span>编辑</span>
                </b-dropdown-item>
                <b-dropdown-item @click="delEvent(row.id)" v-if="$can('delete', 'department')">
                <feather-icon icon="Edit2Icon" class="mr-50"/>
                <span>删除</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>
    </b-card>

    <!-- Update Modal no-fade -->
    <!-- modal-class="modal-sticky" -->
    <b-modal
      id="update-modal"
      :visible="shallShowModal"
      title="部门更新"
      footer-class="d-flex justify-content-between"
      body-class="px-2"
      size="lg"
      centered
      no-close-on-backdrop
      static
      @change="shallShowModal = !shallShowModal"
    >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title"> 部门更新 </h5>
      <div class="modal-actions">
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="shallShowModal = false" />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div>
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="updateForm">
          {{ formUpdate.id > 0 ? '修改' : '添加' }}
        </b-button>
      </div>

      <div>
        <!-- <b-button variant="outline-secondary" @click="resetFormData"> 重置 </b-button> -->
        <b-button variant="outline-secondary" class="ml-75 cursor-pointer" @click="shallShowModal = false"> 取消 </b-button>
      </div>
    </template>

    <!-- Modal: Body -->
    <div class="d-flex justify-content-between"></div>
      <validation-observer ref="refFormObserver">
        <b-form class="mb-1">
          <b-row>
            <!-- name -->
            <b-col cols="6">
                <b-form-group label="部门名称" label-for="name">
                  <validation-provider name="部门名称" #default="{ errors }" rules="required">
                  <b-form-input
                    id="name" :state="errors.length > 0 ? false:null"
                    v-model="formUpdate.name"
                    trim
                    placeholder="例如: 销售部"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- p_id -->
            <b-col cols="6">
              <validation-provider name="上级部门" #default="{ errors }" rules="required">
                <b-form-group label="上级部门" label-for="p_id" :state="errors.length > 0 ? false:null" v-if="$can('select', 'department')">
                  <v-select
                    v-model="formUpdate.p_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pidOptions" :clearable="true" label="name"
                    :reduce="val => val.id"
                  >
                    <template v-slot:option="option">{{ option.html + option.name }}</template>
                    <template v-slot:no-options>没有找到相关信息</template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <!-- code -->
            <b-col cols="6">
                <b-form-group label="部门编码" label-for="code">
                  <validation-provider name="部门编码" #default="{ errors }" rules="required">
                  <b-form-input
                    id="code" :state="errors.length > 0 ? false:null"
                    v-model="formUpdate.code"
                    trim
                    placeholder="例如: U9ERPcode"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- sort -->
            <b-col cols="6">
                <b-form-group label="排序权重" label-for="sort">
                  <validation-provider name="排序权重" #default="{ errors }" rules="required|integer">
                    <b-form-input
                      id="sort" :state="errors.length > 0 ? false:null"
                      v-model="formUpdate.sort"
                      type="number"
                      number
                      trim
                      placeholder="例如: 12"
                    />
                    <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
              </b-form-group>
            </b-col>

            <!-- company_key -->
            <b-col cols="6">
                <b-form-group label="Erp组织编号" label-for="company_key">
                  <validation-provider name="Erp组织编号" #default="{ errors }" rules="required">
                  <b-form-input
                    id="company_key" :state="errors.length > 0 ? false:null"
                    v-model="formUpdate.company_key"
                    trim
                    placeholder="例如: U9ERPcode"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- depart_key -->
            <b-col cols="6">
                <b-form-group label="Erp部门编号" label-for="depart_key">
                  <validation-provider name="Erp部门编号" #default="{ errors }" rules="required">
                  <b-form-input
                    id="depart_key" :state="errors.length > 0 ? false:null"
                    v-model="formUpdate.depart_key"
                    trim
                    placeholder="例如: U9ERPcode"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <!-- status -->
            <b-col cols="12">
              <validation-provider name="部门状态" #default="{ errors }" rules="required|integer">
                <b-form-group label="部门状态" label-for="status" :state="errors.length > 0 ? false:null">
                  <b-form-checkbox id="status"
                    class="col-form-label"
                    v-model="formUpdate.status"
                    :value='1' :unchecked-value='-1'
                    name="status" switch inline />
                </b-form-group>
                <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

          </b-row>

        </b-form>
    </validation-observer>

  </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { ref, watch, onMounted } from '@vue/composition-api'

import Ripple from 'vue-ripple-directive'

// import store from '@/store/index'
import {
  configTabListApi,
  configTabSelectApi,
  configTabDeleteApi,
  configTabStatusApi,
  configTabSaveApi,
} from '@/api/system/config.js'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, integer } from '@validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    formValidation,
    ValidationProvider,
    ValidationObserver,
  },
  setup(props, context) {
    const { $swal, $can } = context.root

    // Use toast
    const toast = useToast()

    const loading = ref(false)
    const allAlign = ref(null)
    const tableData = ref([])

    const refFormObserver = ref(null)

    const shallShowModal = ref(false)
    const formUpdate = ref({ id: 0, p_id: 0, code: '', company_key: '', depart_key: '', name: '', sort: 0, status: 1 })

    const resetFormData = () => {
      formUpdate.value = { id: 0, p_id: 0, code: '', company_key: '', depart_key: '', name: '', sort: 0, status: 1 }
      refFormObserver.value.reset()
    }

    const fetchData = () => {
      configTabListApi()
        .then(res => { tableData.value = res.data })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取部门信息失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    watch(shallShowModal, val => { if (!val) { resetFormData() } })

    const pidOptions = ref([
      { id: 0, p_id: 0, name: '顶级按钮', html: '' }
    ])
    const getPidOptions = () => {
      configTabSelectApi()
        .then(res => { pidOptions.value = res.data })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取部门下拉列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    onMounted(() => {
      if ($can('list', 'department')) {
        fetchData()
      } else {
        toast({
          component: ToastificationContent,
          props: { title: '您没有获取部门列表的权限', icon: 'AlertTriangleIcon', variant: 'danger' },
        })
      }
      if ($can('select', 'department')) {
        getPidOptions()
      } else {
        toast({
          component: ToastificationContent,
          props: { title: '您没有获取部门下拉项的权限', icon: 'AlertTriangleIcon', variant: 'danger' },
        })
      }
    })

    const editEvent = row => {
      formUpdate.value = JSON.parse(JSON.stringify(row))
      shallShowModal.value = true
    }

    const statusEvent = id => {
      configTabStatusApi(id)
        .then(() => {
          fetchData()
          getPidOptions()
          toast({
            component: ToastificationContent,
            props: { title: '部门状态更新成功', icon: 'CheckCircleIcon', variant: 'success' },
          })
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '部门状态更新失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    const updateForm = () => {
      refFormObserver.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          configTabSaveApi(formUpdate.value)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: { title: '更新部门信息成功', icon: 'CheckCircleIcon', variant: 'success' },
              })
              fetchData()
              getPidOptions()
              shallShowModal.value = false
            })
            .catch(err => {
              toast({
                component: ToastificationContent,
                props: { title: '更新部门信息失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
              })
            })
        }
      })
    }

    const delEvent = id => {
      $swal({
        title: '确定删除此部门吗?',
        text: '删除部门会同步删除此部门下的子部门,请知悉!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          configTabDeleteApi(id)
            .then(() => {
              fetchData()
              getPidOptions()
              toast({
                component: ToastificationContent,
                props: { title: '删除部门成功', icon: 'CheckCircleIcon', variant: 'success' },
              })
            })
            .catch(err => {
              toast({
                component: ToastificationContent,
                props: { title: '删除部门失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
              })
            })
        }
      })
    }

    return {
      loading,
      allAlign,
      tableData,

      fetchData,
      delEvent,
      statusEvent,
      editEvent,
      updateForm,

      shallShowModal,
      formUpdate,
      pidOptions,

      required,
      integer,

      refFormObserver,
      resetFormData,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
