import jwt from './useJwt'

// 扩展 请求多服务地址, 多拦截器
function baseRequest(url, method, params, data, { noAuth = false }) {
    return !noAuth ? jwt.axiosIns.request({ url, method, params, data }) : jwt.axiosIns.request({ url, method, params, data })
}
// 处理默认参数为空不传参问题
const request = (api, method, params, data, opt) => baseRequest(api, method, params || {}, data || {}, opt || {})

export default request
